<template>
    <div>
 
        <template v-if="userData">
            <b-col cols="12 mb-4" md="6">

                <b-row>
                    <b-col>
                        User name
                        <b-form-input disabled v-model="userData.amerPersonalInfo[0].email" />
                    </b-col>

                </b-row>
                <b-row>
                    <b-col>
                        <b-button v-b-modal.bv-modal-change-password variant="primary" class="mt-2">
                            Change password
                        </b-button>
                    </b-col>
                </b-row>


            </b-col>
        </template>



        <b-modal id="bv-modal-change-password" centered hide-footer size="md">
            <div class="d-block">
                <h3>Change password</h3>

                <b-col class="mt-2">
                    Password
                    <b-form-input type="password" v-model="password" />
                </b-col>
                <b-col class="mt-2">
                    Confirmation
                    <b-form-input type="password" v-model="confirmation" />
                </b-col>

            </div>
            <b-row class="mt-3">
                <b-col>
                    <b-button block @click="chagePassword($bvModal)">Save</b-button>
                </b-col>
                <b-col>
                    <b-button block @click="$bvModal.hide('bv-modal-change-password')">Close</b-button>
                </b-col>
            </b-row>
        </b-modal>


    </div>
</template>
  
<script>
import {
    BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BFormSelect
} from 'bootstrap-vue'
import Stripe from 'stripe'
import infocontact from '@/navigation/vertical/infocontact'
import { mapGetters } from 'vuex'
import { getMessageError } from '@core/utils/utils'

export default {
    components: {
        BRow,
        BCol,
        BForm,
        BFormGroup,
        BFormInput,
        BButton,
        BInputGroup,
        BInputGroupPrepend,
        BFormSelect
    },
    computed: {
        ...mapGetters({
            userData: 'Users/usersData'
        }),
    },
    data() {
        return {
            password: "",
            confirmation: "",
        }
    },
    methods: {
        chagePassword(bvModal) {


            if (this.password.length < 5) {
                this.$swal({
                    title: "Password length must be more than 4 character",
                    icon: "error",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                });
                return false;
            }

            if (this.password != this.confirmation) {
                this.$swal({
                    title: "Password confirmation is not equal to password",
                    icon: "error",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                });
                return false;
            }
            let url = `auth/admin/changePassword/${this.userData.amera_user.id}`;
            let formData = new FormData();

            formData.append("password", this.password);

            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            this.$http
                .post(url, formData)
                .then((res) => {
                    bvModal.hide("bv-modal-change-password");
                    this.password = "";
                    this.confirmation = "";
                    this.$swal({
                        title: "Password changed",
                        icon: "success",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                })
                .catch((res) => {
                    this.$swal({
                        title: getMessageError(res),
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
        }
    },
    mounted() {
    },

}
</script>
  
<style></style>
  